.wrapper {
  * {
    cursor: pointer;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:focus {
      outline: none;
    }
  }
}
