.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

// custom-scrollbar
.custom-scrollbar::-webkit-scrollbar {
  background-color: #6c6c6c;
  width: 18px;
}

.custom-scrollbar::-webkit-scrollbar-track {
}

.custom-scrollbar::-webkit-scrollbar-thumb:vertical {
  background: #d9d9d9;
  border: 3px solid #6c6c6c;
  border-radius: 999px;
}

.custom-scrollbar::-webkit-scrollbar-button:vertical:increment {
  background-image: url("../svg/ScrollbarButton/decrement.svg");
  background-repeat: no-repeat;
  background-position: 3px 3px;
}
.custom-scrollbar::-webkit-scrollbar-button:vertical:decrement {
  background-image: url("../svg/ScrollbarButton/increment.svg");
  background-repeat: no-repeat;
  background-position: 3px 3px;
}

.custom-scrollbar::-webkit-scrollbar-button:vertical:end:increment,
.custom-scrollbar::-webkit-scrollbar-button:vertical:start:decrement {
  display: block;
}


// custom-scrollbar-none
.custom-scrollbar-none::-webkit-scrollbar {
  height: 0px;
}


.revert-tail {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  path {
    fill: #97ddff;
  }
}
