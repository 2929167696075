@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply font-noto-sans-jp;
  }
}

@layer components {
  .custom-container {
    @apply w-full max-w-[1220px] mx-auto px-3 md:px-5;
  }
  .min-h-content {
    @apply min-h-[calc(100vh-136px)];
  }
}